import { Module } from 'vuex'
import { ICompanySettings } from '@/composable/RequestUtils/useCompanySettingsQuery'
import { EventPayload } from '@/ts-definitions'
import { updateProxyState } from '@/utils/updateProxyState'
import { QuickCanvasModel } from '../interfaces'
import { RootStore } from '../rootStore'
import { defaultProjectSettings } from './designSettings'

export type ProjectStore = {
	data: QuickCanvasModel
	companySettings: ICompanySettings | null
}

type ProjectNamespace = 'project'

type SetModelMutation = EventPayload<
	`${ProjectNamespace}/setProject`,
	Partial<QuickCanvasModel>
>
type SetCompanySettingsMutation = EventPayload<
	`${ProjectNamespace}/setCompanySettings`,
	ICompanySettings
>

export type ProjectMutations = SetModelMutation | SetCompanySettingsMutation

export const projectStore: Module<ProjectStore, RootStore> = {
	namespaced: true,
	state: () => ({
		companySettings: null,
		data: {
			name: '',
			currency: 'USD',
			settings: {
				...defaultProjectSettings,
			},
			is_draft: true,
			is_published: false,
			is_locked: false,
			is_template: false,
			show_on_portals: false,
			parent: '',
			thumbnail_image: '',
			preview_image: '',
			pages: [],
			project_id: '',
			status: 'inactive',
			user: '',
			user_id: '',
			created_at: '',
			updated_at: '',
			short_id: '',
			slug: '',
			limited_to: 'public',
			origin: '',
		},
	}),
	mutations: {
		setProject(state, { payload }: SetModelMutation) {
			updateProxyState(state.data, payload)
		},
		setCompanySettings(state, { payload }: SetCompanySettingsMutation) {
			state.companySettings = payload
		},
	},
}
