import { computed, reactive, ref } from 'vue'
import { debounce, makeInstanceFactory } from '@zoomcatalog/shared'
import { useSelector } from '@/store/rootStore'

const [useMakeAutoSaveInstance, useAutoSaveInstance, useAutoSaveHandlers] =
	makeInstanceFactory('auto-save', () => {
		const isLoading = ref(false)
		const statusText = ref<string | null>(null)

		const queue = useSelector((state) => state.autoSave.queue)
		const isSending = useSelector((state) => state.autoSave.isSending)
		const isPublishHotspotVisible = useSelector(
			(state) => state.autoSave.isPublishHotspotVisible
		)
		const queueLength = computed(() => queue.value.length)
		const hasCommands = computed(() => queueLength.value > 0 || isSending.value)
		const isProcessing = computed(() => hasCommands.value || isLoading.value)
		const queuePercentage = computed(() =>
			queueLength.value === 0 ? 100 : 100 / queueLength.value
		)

		/* Handlers */
		function setIsLoading(loading: boolean) {
			if (isLoading.value === loading) return
			isLoading.value = loading
		}
		function setStatusText(text: string | null) {
			statusText.value = text
		}
		const setStatusTextDebounced = debounce((text: string | null) => {
			statusText.value = text
		}, 1000)

		return [
			reactive({
				isSending,
				queue,
				queueLength,
				hasCommands,
				queuePercentage,
				isLoading,
				statusText,
				isProcessing,
				isPublishHotspotVisible,
			}),
			{
				setIsLoading,
				setStatusText,
				setStatusTextDebounced,
			},
		]
	})

export { useMakeAutoSaveInstance, useAutoSaveInstance, useAutoSaveHandlers }
