import type { DesignMarkUpSettings } from '@zoomcatalog/design-templates'
import { Module } from 'vuex'

import { DesignType } from '@/settings/DesignTypes'
import { EventPayload } from '@/ts-definitions'
import { updateProxyState } from '@/utils/updateProxyState'

import { SetupWizard } from '../interfaces'
import { RootStore } from '../rootStore'

export type DesignSettingsStore = {
	name: string
	currency: string
	isTemplate: boolean
	settings: {
		autoLogo: boolean
		autoLogoData: {
			assetId: string
			url: string
		}
		virtuals: boolean
		virtualsLogo: {
			assetId: string
			url: string
			isExternal: boolean
			parentId?: string
			name?: string
		}
		designMarkup: DesignMarkUpSettings
		pricingSettings: {
			mark_up: string
			price_type: string
			price_label: string
			config_type: string
			custom_mark_up: string
		}
		designType: Omit<DesignType, 'thumbnail'>
		setupWizard: SetupWizard
		autoOpenVsTab: boolean
	}
}

export const defaultProjectSettings: DesignSettingsStore['settings'] = {
	autoLogo: false,
	autoLogoData: {
		assetId: '',
		url: '',
	},
	virtuals: false,
	virtualsLogo: {
		assetId: '',
		url: '',
		isExternal: false,
	},
	designMarkup: {
		mode: 'markup',
		percentage: '',
	},
	pricingSettings: {
		mark_up: '0',
		price_type: 'customer',
		price_label: 'starting at',
		config_type: 'default',
		custom_mark_up: '0',
	},
	designType: {
		name: 'portrait',
		dimensions: [816, 1056],
	},
	setupWizard: {
		started: false,
		completed: false,
		step: 1,
	},
	autoOpenVsTab: false,
}

type DesignSettingsNamespace = 'designSettings'

type SetMutation = EventPayload<
	`${DesignSettingsNamespace}/setSettings`,
	Partial<DesignSettingsStore>
>

export type DesignSettingsMutations = SetMutation

export const designSettingsStore: Module<DesignSettingsStore, RootStore> = {
	namespaced: true,
	state: () => ({
		name: '',
		currency: '',
		isTemplate: false,
		settings: defaultProjectSettings,
	}),
	mutations: {
		setSettings(state, { payload }: SetMutation) {
			updateProxyState(state, payload)
		},
	},
}
